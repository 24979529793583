.passwordInput {
}
.toggleBtn {
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0.1rem;
  font-size: inherit;
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.1s linear;
}
.toggleBtn:hover {
  color: rgba(0, 0, 0, 1);
}
