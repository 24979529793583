.box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 3rem;
}

.content {
  transition: all 0.5s var(--ease-in-out-quad) 0.4s, max-height 0.5s var(--ease-in-out-quad);
  box-sizing: border-box;
  background: #f1f2f3;
  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.2);
}

.container {
  display: grid;
  grid-template-columns: 1fr 0rem;
  height: 100%;
  transition: all 0.5s var(--ease-in-out-quad);
}
.container.panel {
  grid-template-columns: 1fr 41rem;
}

.children {
  position: relative;
  display: flex;
  flex: auto;
  overflow: hidden;
  z-index: 1;
}
