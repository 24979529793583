.closed {
  background-color: var(--white);
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.view {
  padding: 5.4rem;
  max-width: 521px;
}
