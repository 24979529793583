.heading {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  z-index: 10;
}

.padding {
  padding: 2.8rem 2.3rem 1.2rem 5.4rem;
  height: 10rem;
}

.nav {
  position: absolute;
  right: 2.3rem;
  top: 8rem;
}

.title {
  display: flex;
  flex: auto;
  justify-content: center;
}

.backBtn {
  color: rgba(0, 0, 0, 1);
  justify-content: flex-start;
}

.chevron {
  font-size: 1.6rem;
}

.burger {
  display: flex;
  justify-content: flex-end;
}
