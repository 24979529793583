.optionList {
  --border-color: rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-flow: column;
  height: fit-content;
  position: relative;
}

.optionList.loading::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: inherit;
  z-index: 1;
  animation: show 0.2s linear;
}

.header {
  padding: 6.4rem 3rem 14rem;
  box-sizing: border-box;
  flex-grow: 1;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  padding: 3rem;
  border-top: 1px solid var(--border-color);
}

.optionBtn {
  color: #444444;
}
