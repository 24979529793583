@import url("./styles/_easing.css");

:root {
  --primary: #439fe2;
  --hover: #1c85d1;
  --active: #146aa9;
  --white: #fff;
  --black: #000;

  --error: #d6183d;
  --danger: #ff491f;

  --grey: #565656;
  --green: #4ccb75;
}

* {
  margin: 0;
  color: inherit;
  font-weight: inherit;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 10px;
  background: url(./images/bg-image.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

html,
body,
#root,
#root > div {
  height: 100%;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.login-box-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box-container::after {
  content: "";
  background-color: #272727;
  background-image: url(images/corporate-assistant.png);
  background-size: cover;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.login-box {
  background-color: #fff;
  height: 446px;
  width: 300px;
  padding: 38px 30px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.login-box-header {
  color: #404040;
  font-size: 19pt;
  font-weight: bold;
}

.login-box-subheader {
  color: #939293;
  font-size: 13pt;
  margin-top: 20px;
  margin-bottom: 50px;
}

.input-label {
  font-size: 8pt;
  color: #d9d9d9;
  margin: 15px 0;
}

.login-box-credentials > div,
.login-box-credentials > input {
  margin-bottom: 10px;
}

.login-box-credentials input {
  font-size: 13pt;
  border-bottom: 1px solid #43a0e3;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  padding-bottom: 10px;
}

.login-box-actions {
  padding: 20px 0;
}

.login-box-actions button {
  background-color: #43a0e3;
  color: #fff;
  width: 100px;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.error {
  color: red;
  font-size: 9pt;
}

.chat-wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.chat-list,
.booking-list {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 410px;
}
.chat-list {
  background-color: #f1f2f3;
  width: 100%;
}
.chat-list-header {
  height: 85px;
  background-image: url(../src/images/logo-text.png);
  background-position: 15px 15px;
  background-size: 220px auto;
  background-repeat: no-repeat;
}
.group-list {
  padding: 15px;
}
.chat-converstation {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 80px;
}
.chat-list-items {
  padding-left: 54px;
  padding-right: 54px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.chat-list-item {
  display: flex;
  align-items: center;
  gap: 19px;
  border-radius: 20px;
  font-size: 16px;
  margin-bottom: 12px;
  padding: 10px 18px 9px;
  word-break: break-word;
  white-space: pre-line;
  box-sizing: border-box;
  max-width: 580px;
}
.chat-list-item-with-action {
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.action-btn {
  background-image: url(../src/images/action-btn.svg);
  background-size: cover;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
}
.chat-time {
  display: block;
  text-align: center;
  opacity: 0.6;
  font-size: 12px;
  align-self: center;
  margin-bottom: 22px;
  margin-top: 44px;
}
.chat-list-item-agent {
  background-color: #fff;
  border-bottom-left-radius: 0;
  color: rgba(0, 0, 0, 0.6);
}
.chat-list-item-user {
  background-color: var(--primary);
  color: #fff;
  border-bottom-right-radius: 0;
  align-self: flex-end;
}
.image-preview {
  background-size: cover;
  height: 150px;
  width: 225px;
}
.booking-list {
  width: 100%;
  display: flex;
  background-color: #272727;
}
.chat-list-input-wrapper {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  background-color: #f1f2f3;
}
.chat-input {
  padding-left: 45px;
  padding-right: 45px;

  color: #fff;
  /* height: 100%; */
}
.chat-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: 8px;
  gap: 1rem;
  justify-content: space-between;
}
.chat-input-teaxtarea {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.75);
  width: calc(100% - 65px);
  border: none;
  padding: 0px;
  height: 26px;
  font-size: 16px;
  overflow: hidden;
  resize: none;
  font-family: inherit;
}
.chat-input-teaxtarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.image-upload-preview {
  background-color: transparent;
  color: #404040;
  width: calc(100% - 65px);
  border: none;
  overflow: hidden;
  resize: none;
  display: inline-block;
}
.image-upload-preview img {
  max-height: 100%;
  max-width: calc(100% - 65px);
}
.send-image-button {
  height: 28px;
  width: 28px;
  background-image: url(../src/images/image-attachment-gem.svg);
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;

  background-color: transparent;
}
.delete-button {
  height: 32px;
  width: 32px;
  background-image: url(../src/images/delete.png);
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 5px;
  background-color: transparent;
}
.send-message-button {
  height: 30px;
  width: 30px;
  background-image: url(../src/images/send-message.svg);
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 5px;
  background-color: transparent;
  transition: all 0.1s var(--ease-out-quad);
}
.send-message-button:hover {
  transform: scale(1.1);
}
.chat-input > hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  height: 0;
  margin-bottom: 30px;
}
.booking-wrapper {
  padding: 45px 30px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}
.booking-tabs {
  color: var(--white);
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.booking-tab {
  border-bottom: 1px solid transparent;
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: inline-block;
  padding-bottom: 10px;
  opacity: 0.5;
  transition: opacity 0.1s linear;
}
.booking-tab:hover {
  opacity: 1;
}
.booking-tab.active {
  opacity: 1;
  border-bottom-color: #43a0e3;
}
.booking-tab.select {
  opacity: 1;
  margin-top: 50px;
  width: 100%;
}
.booking-tab-toggle {
  width: 100%;
  border: 0;
  background: url("./images/down-arrow.svg") right 4px no-repeat;
  outline: none;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 16px;
  padding: 0;
}

.booking-tab-toggle::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 9px;
  background: rgba(255, 255, 255, 0.2);
}

.receipt-tabs {
  background-color: #f5f5f5;
  color: #6a6a6a;
  margin-bottom: 30px;
  padding-top: 20px;
}
.receipt-tab {
  border-bottom: 1px solid transparent;
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: inline-block;
  padding-bottom: 10px;
}
.receipt-tab.active {
  border-bottom-color: #43a0e3;
}
.receipt-wrapper {
  justify-content: space-around;
  display: flex;
}
.tab-container button {
  background-color: #43a0e3;
  color: #fff;
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 8pt;
}
.booking-items {
  overflow-y: auto;
  height: 100%;
  padding: 0;
}
.empty-item {
  max-height: 0;
  overflow: hidden;
}

.booking-item {
  overflow-y: auto;
  max-height: 100%;
  transition: max-height 1s ease-out;
}
.booking-items-list {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  flex-direction: column;
}
.booking-items .header {
  width: 100%;
}

.card {
  background-color: #fff;
  margin-bottom: 20px;
  width: 100%;
}
.open {
  z-index: 2;
}
.header {
  width: 100%;
  background-color: #f5f5f5;
}
.logo {
  position: absolute;
  left: 20px;
  width: 167px;
  height: 45px;
}
.logo-text {
  color: #444444;
  font-size: 17px;
  font-weight: bold;
  padding-left: 20px;
}
.grid {
  padding-top: 29px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 24px;
}
.grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.6);
}
.grid-row.route {
  margin-bottom: 4px;
}
.grid-row.routeCode {
  margin-bottom: 29px;
}
.grid-row.reference .left-col .div,
.grid-row.reference .center-col .div,
.grid-row.reference .right-col .div {
  margin-bottom: 4px;
}
.grid-row.reference .center-col {
  text-align: left;
}
.grid-row.reference .right-col {
  justify-content: flex-end;
  display: flex;
}
.travellers-card {
  padding: 0px 20px 20px;
  color: #439fe2;
}
.travellers {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.traveller {
  color: #439fe2 !important;
}

.traveller:hover {
  cursor: pointer;
}

.bold-div {
  font-weight: bold;
  font-size: 14px;
  color: #444444;
}
.row-container {
  display: flex;
  gap: 1rem;
}
.left-col {
  flex: 1;
  width: 33.3%;
  text-align: left;
}
.center-col {
  flex: 1;
  width: 33.3%;
  text-align: center;
}
.center-col-row2 {
  width: 100%;
}
.right-col {
  flex: 1;
  width: 33.3%;
  align-items: flex-end;
  text-align: right;
}
.right-col-row2 {
  width: 10%;
}
.text {
  color: #6a6a6a;
  line-height: 24px;
}
.airport {
  font-size: 36px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}
.flight-icon {
  width: 80px;
  height: 20.5px;
  margin-top: 11px;
  /* margin-bottom: 20px; */
  align-self: center;
}
.icon-large {
  margin-top: 12px;
  width: 100;
  height: 25.5px;
}
.bold-text {
  font-size: 18px;
  font-weight: bold;
  color: #444444;
  margin-top: 12px;
}
.action-button {
  cursor: pointer;
  float: right;
  width: 20px;
  display: flex;
  justify-content: space-between;
  padding: 2px 0px;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #439fe2;
}
.info {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.info-open {
  max-height: 500px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  transition: max-height 0.2s ease-in;
}
.whole-info {
  background-color: #f5f5f5;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}
.row {
  width: 100%;
  padding-top: 28px;
  display: flex;
  margin-bottom: 20px;
  gap: 1rem;
}
.info-bold {
  flex-basis: 34%;
  flex-grow: 0;
  font-weight: bold;
  line-height: 22px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.info-text {
  flex-basis: auto;
  align-self: flex-end;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.receipt {
  width: 100%;
  height: 0;
  background-color: #fff;
  overflow: hidden;
}
.receipt-open {
  height: auto;
  overflow: visible;
}
.tab-container {
  flex: 1;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}
.receipt-left-col {
  flex: 0.66;
}
.receipt-right-col {
  flex: 0.33;
}
.button {
  width: 100%;
  height: 60px;
  background-color: #439fe2;
  justify-content: center;
}
.button-label {
  font-size: 17px;
  color: #fff;
  text-align: center;
  background-color: transparent;
}
.travel-bill {
  margin-bottom: 35px;
}
.bill-title {
  font-weight: bold;
  margin-bottom: 35px;
  color: #444444;
}
.bill-text {
  color: #969696;
  line-height: 24px;
}
.chat {
  background-color: #242424;
}
.empty-notice {
  color: #d9d9d9;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-notice > div {
  text-align: center;
}
.empty-text {
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}
.empty-text::before {
  content: "";
  display: block;
  background: url("images/logo.svg") 0 0 no-repeat;
  width: 65px;
  height: 53px;
  margin: auto auto 24px auto;
  background-position: center;
}
.image-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.message-author-icon {
  height: 16px;
  width: 20px;
}
.message-author-name {
  margin-left: 8px;
  font-weight: bold;
}
