.textItem {
  display: inline-flex;
  align-items: flex-start;
  gap: 2rem;
  row-gap: 0.5rem;
  border-radius: 2rem;
  font-size: 1.6rem;
  padding: 1rem 1.8rem 0.9rem;
  word-break: break-word;
  white-space: pre-line;
  box-sizing: border-box;
  max-width: 80%;
  background-color: #fff;
  border-bottom-left-radius: 0;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  flex-flow: column;
  justify-content: flex-start;
  line-height: 2rem;
}

.altsItem {
  color: var(--primary);
  border-radius: 2rem;
  border-bottom-right-radius: 0;
  align-self: flex-end;
  border: 0.1rem solid var(--primary);
  padding: 1rem 1.8rem 0.9rem;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
}

.altSelected {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 2rem;
  border-bottom-right-radius: 0;
  align-self: flex-end;
  border: 0.1rem solid var(--primary);
  padding: 1rem 1.8rem 0.9rem;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
}

.button {
  background-color: var(--white);
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
}
