.radio {
  --border: rgba(0, 0, 0, 0.2);
  --text: rgba(0, 0, 0, 0.3);
  --text-active: var(--white);

  display: flex;
  flex-shrink: 0;
  line-height: 2.5rem;
  color: var(--text);
  flex-flow: row nowrap;
  white-space: nowrap;
  cursor: pointer;
}

.radio:last-of-type .label {
  border: 1px solid var(--border);
}

.label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: inherit;
  border: 1px solid var(--border);
  border-right: none;
  padding: 1.3rem 2.2rem 1.4rem;
  z-index: 0;
}

.element {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.children {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  font-size: 3.2rem;
  margin-left: -1.2rem;
  display: inline-flex;
}

.icon:only-child {
  font-size: 1.4rem;
  margin-left: 0;
}

/* Solid */
.solid .mark {
  width: 100%;
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border-radius: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  display: none;
}
.solid .mark::before {
  display: none;
}

.solid .element:checked + .mark {
  display: block;
}

.solid .element:checked + .mark + .children {
  color: var(--text-active);
}

/* Button */
.button {
  flex: inherit;
  position: relative;
  z-index: 0;
  flex: unset;
}
.button .label {
  width: 100%;
  border-radius: 4.8rem;
  border: 1px solid var(--border);
  transition: background-color 0.1s linear, color 0.1s linear;
}

.button .label:hover {
}

.button .mark {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border-radius: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  display: none;
  cursor: pointer;
}
.button .mark::before {
  display: none;
}

.button .element:checked + .mark {
  display: block;
}

.button .element:checked + .mark + .children {
  color: var(--text-active);
}
