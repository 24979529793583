@keyframes show-background {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show-close {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(0);
  }
}

.lightRoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.lightRoom::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  animation: show-background 0.2s linear;
}

.dialog {
  display: flex;
  overflow: hidden;
}

.image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 4px 21px 0px #00000026;
  border-radius: 1rem;
}

.closeBtn {
  position: absolute;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 1.4rem;
  top: 2rem;
  left: 2rem;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  padding: 0;
  cursor: pointer;
  transition: all 0.1s linear;
  color: white;
  animation: show-close 0.4s var(--ease-out-quad) 0.1s;
  animation-fill-mode: backwards;
}
