.appDetails {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 2rem;
}

.info {
  display: flex;
  flex-flow: column;
  row-gap: 0.8rem;
}

.stars {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.3rem;
}
