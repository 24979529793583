.dropdown {
  position: relative;
}

.content {
  display: flex;
  flex-flow: column;
  border-radius: 5px;
  background-color: var(--white);
  width: 100%;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  transition: all 0.2s var(--ease-in-out-quad);
  position: relative;
}

.secondary {
  background-color: var(--primary);
  border-radius: 0;
  color: var(--white);
}

.floating {
  position: absolute;
  z-index: 10;
  top: 0;
  left: -6px;
  width: calc(100% + 12px);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

.secondary .list {
  max-height: 38rem;
}

.button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  text-align: left;
  font-size: 1.6rem;
  line-height: 1.9rem;
  position: relative;
  flex: 1;
  display: flex;
  padding: 1.4rem 1.8rem;
  display: flex;
  flex: 1;
  width: 100%;
  cursor: pointer;
  transition: background 0.1s linear;
}
.button:hover {
  background: rgba(0, 0, 0, 0.05);
}
