.input {
  --transition: all 0.1s linear;
  font-size: 1.6rem;
  line-height: 1.875rem;
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 1rem;
  flex: auto;
  margin-top: -1.2rem;
}

.input.empty .element::-webkit-datetime-edit {
  font-family: "Roboto", Helvetica;
  opacity: 0;
}
.input.focus .element::-webkit-datetime-edit {
  opacity: 1;
}

.label {
  display: flex;
  flex-flow: column;
  transition: var(--transition);
  row-gap: 0;
  position: relative;
  color: var(--primary);
}
.label.empty {
  top: 1.9rem;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4);
}
.label.focus {
  color: var(--primary);
  top: 0;
}
.label.error {
  color: var(--error);
}

label:has(input:autofill) .label {
  top: 0rem;
}

.icon {
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 1.8rem;
}

.errorMessage {
  margin-top: 0.3rem;
  font-size: 1rem;
  color: var(--error);
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: start;
}
.errorIcon {
  position: relative;
  top: 0.4rem;
}

.elementWrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.4rem;
  padding-block: 0.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.element {
  border: none;
  transition: var(--transition);
  width: 100%;
  font-size: inherit;
  text-align: inherit;
  color: var(--grey);
  background: transparent;
  margin-top: 0;
  font-family: inherit;
}

.element.disabled {
  pointer-events: none;
  opacity: 0.85;
}

.element.error,
.element.error:focus {
  border-color: var(--error);
}

.element:focus {
  border-color: var(--primary);
}
.element:focus::placeholder {
  opacity: 0.2;
}

.element::placeholder {
  transition: var(--transition);
  color: var(--grey);
  opacity: 0;
}

.element::-webkit-outer-spin-button,
.element::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.element:-webkit-autofill,
.element:-webkit-autofill:hover,
.element:-webkit-autofill:focus,
.element:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.element[type="number"] {
  -moz-appearance: textfield;
}

.element::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.4;
}

.selectedItems {
  display: flex;
  gap: 0.5rem;
  padding: 0;
}

.selectedItemWrapper {
  background-color: var(--primary);
  color: var(--white);
  width: fit-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.selectedItem {
  font-weight: 600;
  letter-spacing: 0.05em;
}

.removeItemButton {
  cursor: pointer;
  border: none;
  background: var(--primary);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.removeIcon {
  font-size: 1.2rem;
}
