.typingItem {
  align-self: flex-end;
  margin-top: 1.8rem;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.6);
}

.container {
  display: flex;
  width: 50px;
  height: 12px;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem 1.8rem 0.9rem;
}
.dot {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #cbcbcb;
  animation: zoom 1.2s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0.4s;
}
.dot:nth-child(2) {
  animation-delay: 0.8s;
}
.dot:nth-child(3) {
  animation-delay: 1.2s;
}

@keyframes zoom {
  50% {
      transform: scale(1.43);
  }
}