@keyframes show {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.toast {
  position: fixed;
  width: 100%;
  z-index: 30;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s linear;
  padding: 0 1.6rem;
  color: white;
}

.content {
  padding: 1.6rem 2.4rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1.6rem;
  background-color: #f1f2f3;
  color: #fff;
  width: 100%;
  height: auto;
  max-width: 80rem;
  position: relative;
  box-shadow: 0px 0.5px 4px rgba(32, 46, 61, 0.12), 0px 6px 13px rgba(32, 46, 61, 0.12);
  border-radius: 0 0 2.4rem 2.4rem;
  animation: show 0.4s var(--ease-out-quad);
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.75);
}
.content.error {
  color: rgba(0, 0, 0, 0.75);
  background-color: #f1f2f3;
}

.closeBtn {
  font-size: 1rem;
  top: 1.4rem;
  right: 1.4rem;
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1 / 1;
  align-self: self-start;
  border-radius: 50%;
  background-color: white !important;
}

.closeIcon {
  font-size: 1.6rem;
  color: var(--grey);
}
