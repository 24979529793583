.imageItem {
  display: inline-flex;
  align-items: flex-start;
  border-radius: 2rem;
  background: transparent;
  border: none;
  padding: 0;
}

.imageItem + .imageItem {
  padding-top: 0;
}

.imageItem.user {
  align-self: flex-end;
}

.imageItem .preview:first-of-type {
  border-radius: 2rem;
  border-bottom-left-radius: 0;
}

.imageItem.user .preview:first-of-type {
  border-radius: 2rem;
  border-bottom-right-radius: 0;
}

.name {
  font-weight: bold;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.75);
}

.images {
  position: relative;
}

.preview {
  box-shadow: -6px 0px 19px 0px #00000026;
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  display: inline-flex;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  min-width: 20rem;
  background: #f0f0;
  opacity: 0;
  transition: opacity 1s linear 0.4s;
}

.preview:first-child .image {
  height: 34.6rem;
  max-width: 41rem;
  position: relative;
  width: auto;
}
.preview:first-child .image.small {
  height: 20rem;
}

.preview:first-child .image.loaded {
  opacity: 1;
}

.preview:first-child .image.loading {
  width: 41rem;
}

.imgLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
