.button {
  z-index: 0;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 1.4rem 4.6rem 1.3rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.875rem;
  color: var(--white);
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: color 0.12s linear, background 0.12s linear;
  background: var(--primary);
}
.button.grow {
  flex: auto;
}

.button:focus {
  outline: none;
}
.button:hover {
  background-color: var(--hover);
}
.button:active {
  background-color: var(--active);
}

.button.danger,
.button.danger:hover,
.button.danger:active {
  background: var(--danger);
}

.button.secondary {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: transparent;
}
.button.secondary:hover {
  border-color: var(--hover);
  color: var(--hover);
}
.button.secondary:active {
  border-color: var(--active);
  color: var(--active);
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.button.withIcon {
  background: transparent;
  gap: 1rem;
  padding: initial;
  font-weight: normal;
  font-size: 1.6rem;
  transition: all 0.2s var(--ease-out-quad);
}
.button.withIcon .children {
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  top: 1px;
  transition: all 0.2s var(--ease-out-quad);
}
.button.withIcon .icon {
  color: inherit;
  font-size: 2.8rem;
  display: inline-flex;
  transition: all 0.2s var(--ease-out-quad);
}

.button.withIcon:hover .children {
  color: rgba(0, 0, 0, 0.6);
}
