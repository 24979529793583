.dialog {
  display: flex;
  flex-flow: column;
  row-gap: 1.8rem;
}

.children {
  display: flex;
  flex: 1;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 2.4rem;
  justify-content: center;
  width: 100%;
}
