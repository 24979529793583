.queueItem {
  align-self: flex-end;
  margin-top: 1.8rem;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.6);
}

.button {
  color: var(--primary);
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  display: inline;
  cursor: pointer;
}
