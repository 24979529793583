.stepSlider {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.slider {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
}

.slides {
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.6s var(--ease-in-out-circ);
}

.slide {
  display: flex;
  flex-shrink: 0;
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
  gap: 1.8rem;
}

.pagination {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 2rem;
}

.prevBtn {
  font-size: 3.4rem;
}

.paginationLabel {
  opacity: 0.4;
}
