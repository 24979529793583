.label {
  font-size: 1.1rem;
  color: var(--white);
  font-weight: bold;
  border-radius: 5px;
  background: #439fe2;
  padding: 0.4rem 0.6rem 0.3rem;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
}
