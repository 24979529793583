.slider {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.padding {
  padding: 10rem;
}

.wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.slides {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  transition: all 0.6s var(--ease-in-out-circ);
}

.slide {
  display: flex;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.slide::-webkit-scrollbar {
  display: none;
}

.nextBtn,
.prevBtn {
  position: absolute;
  z-index: 1;
  width: 4rem;
  height: 4rem;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  padding: 0;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s linear;
  color: white;
}
.nextBtn.disabled,
.prevBtn.disabled {
  cursor: auto;
  opacity: 0.2;
}

.nextBtn {
  right: 4rem;
}

.prevBtn {
  left: 4rem;
}

.pagination {
  position: absolute;
  bottom: 5rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  left: 0;
  gap: 0.8em;
  z-index: 2;
  justify-content: center;
  width: 100%;
}

.paginationDot {
  width: 1rem;
  height: 1rem;
  background: #939393;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.paginationDot.active {
  background: white;
}
