.chat {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
  height: 100%;
  padding: 0 5.4rem 5.4rem;
  overflow-y: auto;
}
