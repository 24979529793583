.logo {
  font-size: 4.8rem;
  margin-bottom: 3.8rem;
}

.loader {
  align-items: center;
}

.loginPage {
  height: 100%;
}