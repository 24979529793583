.createGroupForm {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5.5rem;
  height: 100%;
  width: 100%;
}

.members {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 3.5rem;
  width: 100%;
}

.member {
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: none;
}

.addIcon {
  color: var(--green);
  align-self: flex-start;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  gap: 1.7rem;
}

.form {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 1rem;
  flex: none;
}
