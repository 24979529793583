@keyframes checkboxEffext {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.checkbox {
  --border: rgba(0, 0, 0, 0.2);
  --text: rgba(0, 0, 0, 0.3);
  --text-active: var(--white);

  display: flex;
  flex-shrink: 0;
  line-height: 2.5rem;

  flex-flow: row nowrap;
  transition: opacity 0.1s linear;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.label {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--text);
  position: relative;
}

.children {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  font-size: 3.2rem;
  margin-left: -1.2rem;
  display: inline-flex;
}

.icon:only-child {
  font-size: 1.4rem;
  margin-left: 0;
}

.element {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.element:checked + .mark {
  background-image: url("./assets/checkmark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.3rem 0.9rem;

  &::after {
    animation: checkboxEffext;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-fill-mode: backwards;
  }
}

.mark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  aspect-ratio: 1 / 1;
  border: 1px solid var(--primary);
  margin-right: 0.8rem;
  border-radius: 0.4rem;
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  transform: translateY(-0.15em);
}
.mark::after {
  content: "";
  position: absolute;
  pointer-events: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid var(--border);
  border-radius: inherit;
  border: inherit;
  border-color: var(--primary);
  opacity: 0;
}

/* Button */
.button {
  flex: inherit;
  position: relative;
  z-index: 0;
  flex: unset;
}
.button .label {
  font-size: 1.6rem;
  border-radius: 4.8rem;
  border: 1px solid var(--border);
  padding: 0.8rem 2.1rem 0.9rem;
  transition: background-color 0.1s linear, color 0.1s linear;
}
.button .label.checked {
  /* border: 1px solid var(--primary); */
}

.button .label:hover {
}

.button .mark {
  border: 1px solid transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  border-radius: inherit;
  left: 0px;
  top: 1px;
  z-index: -1;
  display: none;
  cursor: pointer;
}
.button .mark::after {
  display: none;
}

.button .element:checked + .mark {
  display: inline-flex;
}

.button .element:checked + .mark + .children {
  color: var(--text-active);
}
