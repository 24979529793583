.iconButton {
  color: var(--primary);
  background: transparent;
  outline: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
}
