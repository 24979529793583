.fileName {
  padding: 1rem 1.2rem;
  background: var(--white);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}

.removeIcon {
  font-size: 1.8rem;
  color: var(--danger);
}
