.item {
  display: flex;
  flex-flow: column;
  row-gap: 0.2em;
  column-gap: 1.6rem;
  flex: 1 0 auto;
  max-width: 100%;
}

.content {
  display: flex;
  flex-flow: column;
  row-gap: 1.6rem;
  max-width: 100%;
  font-size: inherit;
}

.label {
  color: rgba(0, 0, 0, 0.4);
}

.error {
  color: var(--error);
  font-size: 0.8em;
  margin-top: 1.2em;
}
