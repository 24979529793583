@keyframes show {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.cardAuth {
  width: 100%;
  height: 100%;
  position: relative;
}

.iframe {
  border: none;
  padding: 0;
  margin: 0;
  animation: show 0.5s linear;
  position: relative;
  z-index: 1;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: show 0.2s linear;
}
