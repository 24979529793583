.autoComplete {
  position: relative;
}
.toggleBtn {
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0.5rem;
  font-size: inherit;
}

.dropdown {
  position: absolute;
  top: 4.8rem;
  left: 0;
  width: 100%;
  z-index: 10;
}
