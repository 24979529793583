.travellersForm {
  display: flex;
  flex-flow: column;
  /* overflow: hidden; */
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5.5rem;
  height: 100%;
  width: 100%;
}

.travellers {
  flex: 1;
  display: flex;
  flex-flow: column;
  /* overflow-y: auto; */
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5.5rem;
  width: 100%;
}

.traveller {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.addIcon {
  color: var(--green);
  align-self: flex-start;
}

.addBtn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: pink;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  gap: 1.7rem;
}
