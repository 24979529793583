.content {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
}

.view {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
}

.form {
  padding: 5.4rem;
  background-color: var(--white);
  box-sizing: border-box;
}

.closed {
  padding: 16.4rem;
  background-color: var(--white);
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 3rem;
}

.groupSettings {
  margin-top: 5rem;
}
