.noSupport {
  color: #fff;
  padding: 3rem;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.logo {
  color: #fff;
  font-size: 4rem;
  margin-bottom: 4rem;
}

.heading {
  color: #fff;
  margin-bottom: 0.6rem;
}

.text {
  color: #fff;
}

.list {
  display: flex;
  flex-flow: column;
  row-gap: 2rem;
  margin-top: 2.4rem;
  width: 100%;
  max-width: 50rem;
}

.platform {
  padding: 1.2rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.2);
}

.btn {
  display: flex;
  margin-top: 2.4rem;
  border-radius: 2.4rem;
}

.center {
  text-align: center;
}
