.chatPreview {
  display: grid;
  grid-template-columns: 5rem 1fr 12rem;
  gap: 2.5rem;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  text-align: left;
  padding: 1rem 0;
  transition: all 0.2s var(--ease-out-quad);
}
.chatPreview:hover {
  background: rgba(0, 0, 0, 0.01);
  grid-template-columns: 5.4rem 1fr 12rem;
}

.icon {
  display: flex;
  font-size: 3rem;
  width: 5rem;
  height: 5rem;
  align-items: center;
  justify-content: center;
  color: white;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
}
.icon.unread::before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  background-color: var(--primary);
  border-radius: 50%;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 2;
  border: 4px solid #f0f0f0;
}
.image {
  aspect-ratio: 1 / 1;
  border-radius: inherit;
  overflow: hidden;
}

.details {
  display: flex;
  flex-flow: column;
  gap: 0.24rem;
}

.dateTime {
  font-size: 1.2rem;
  text-align: right;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50rem;
  white-space: nowrap;
}
.groupTitle,
.groupPreamble {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50rem;
  white-space: nowrap;
  min-height: 2.2rem;
}
