@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hide {
  height: 0;
  opacity: 0;
}

.content {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  flex: 1;
  opacity: 0;
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
  height: 100%;
}

.fadeOut {
  animation: 0.2s fadeOut forwards;
}

.noSupport {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
}

@media (max-width: 1000px) {
  .noSupport {
    display: flex;
  }
  .box {
    display: none;
  }
}

@media (hover: none) {
  .noSupport {
    display: flex;
  }
  .box {
    display: none;
  }
}
