.burger {
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0;
  flex-flow: column;
  gap: 0.4rem;
  padding: 1.5rem 1.2rem;
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
  transition: background 0.1s linear;
  cursor: pointer;
}
.burger::before,
.burger::after {
  content: "";
  display: block;
  width: 1.6rem;
  background: black;
  height: 3px;
}
.burger:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
