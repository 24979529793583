@keyframes show {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.dashboard {
  display: flex;
  flex-flow: column;
  row-gap: 2rem;
  background-color: #f0f0f0;
  flex: auto;
  width: 100%;
  padding: 4.7rem 5.5rem;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  overflow-y: auto;
}
.dashboard::after {
  content: "";
  display: block;
  background: linear-gradient(0deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
  height: 8rem;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.groupHeading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.7rem;
  margin-bottom: 2rem;
  animation: show 0.6s var(--ease-out-quad);
  animation-fill-mode: backwards;
}

.addBtn {
  color: var(--green);
  flex-flow: row-reverse;
}

.groups {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  padding-bottom: 4rem;
}

.chat {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  animation: show 0.6s var(--ease-out-quad);
  animation-fill-mode: backwards;
}

.menu {
  position: absolute;
  bottom: -2rem;
  right: 0;
}

.nav {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.loader {
  animation: show 0.4s var(--ease-out-quad) 0.1s;
  animation-fill-mode: backwards;
}
