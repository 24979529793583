.title {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;
}

.gender {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.6rem;
  margin-top: -1rem;
}

.genderBtn > * {
  padding: 1rem 2.1rem;
}

.removeIcon {
  color: var(--danger);
  font-size: 2rem;
}

.removeBtn {
  font-size: 1.4rem;
}
