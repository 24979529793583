.form {
  display: flex;
  flex-flow: column;
  row-gap: 2.4rem;
}

.styled {
  flex: 1;
}

.form hr {
  width: 100%;
  border: none;
  display: flex;
  border-top: 1px solid #e6e6de;
  margin: 0.8rem auto;
}

.content {
  display: grid;
  row-gap: 3.6rem;
  gap: 3.6rem;
}
.content.single {
  grid-template-columns: 1fr;
}
.content.double {
  grid-template-columns: 1fr 1fr;
}

.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 2.4rem;
  flex-flow: row;
  margin-top: 3.6rem;
}

.error {
  font-size: 1rem;
  color: var(--error);
}
