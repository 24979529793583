.title {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}

.removeIcon {
  color: var(--danger);
  font-size: 2rem;
}

.removeBtn {
  font-size: 1.4rem;
}

.memberForm {
  display: flex;
  flex-flow: column;
  flex: 1;
}
