.radioGroup {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex: auto;
}
.radioGroup.left {
  justify-content: flex-start;
}
.radioGroup.right {
  justify-content: flex-end;
}
.radioGroup.center {
  justify-content: center;
}

.gap {
  gap: 1rem;
  row-gap: 1rem;
}

.singleLine {
  flex-wrap: nowrap;
}
