.chatItem, .sharedQuestion {
  display: inline-flex;
  align-items: flex-start;
  gap: 2rem;
  row-gap: 0.5rem;
  border-radius: 2rem;
  font-size: 1.6rem;
  padding: 1rem 1.8rem 0.9rem;
  word-break: break-word;
  white-space: pre-line;
  box-sizing: border-box;
  max-width: 80%;
  background-color: #fff;
  border-bottom-left-radius: 0;
  color: rgba(0, 0, 0, 0.6);
  align-self: flex-start;
  flex-flow: column;
  justify-content: flex-start;
  line-height: 2rem;
}

.chatItem.user, .sharedQuestion.user {
  background-color: var(--primary);
  color: #fff;
  border-radius: 2rem;
  border-bottom-right-radius: 0;
  align-self: flex-end;
}

.chatItem.sharedAnswer {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
}

.chatItem .sharedAnswer {
  margin-top: 2rem;
}

.chatItem.action {
  padding-right: 6px;
  padding-top: 9px;
  padding-bottom: 8px;
}

.chatItem a:link, a:visited {
  color: var(--primary);
  text-decoration: none;
}

.chatItem strong {
  font-weight: bold;
}

.sharedQuestion {
  z-index: 1;
  margin-bottom: -3rem;
}

.name {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.75);
}

.content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
}

.actionBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green);
  color: var(--white);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
}
.actionBtn:hover .actionIconArrow {
  transform: translateX(0.3rem);
}

.logo {
  font-size: 1.8rem;
}

.actionIconArrow {
  font-size: 1.6rem;
  transition: all 0.2s var(--ease-out-quad);
}

.actionIconCheckmark {
  font-size: 1.2rem;
}
