.titleLevel1 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 3.6rem;
  line-height: 4.3rem;
  font-weight: 700;
}

.titleLevel2 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: bold;
}

.titleLevel3 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 3.6rem;
  line-height: 4.3rem;
  font-weight: bold;
}

.titleLevel4 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
}

.title {
  color: rgba(0, 0, 0, 0.75);
}

.paragraph {
  color: rgba(0, 0, 0, 0.75);
}
.paragraph a {
  text-decoration: none;
  color: var(--primary);
}

.paragraphLevel1 {
  font-size: 1.8rem;
  line-height: 2.52rem;
}

.paragraphLevel2 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.bold {
  font-weight: 700;
}

.italic {
  font-weight: normal;
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.light {
  color: rgba(0, 0, 0, 0.6);
}

.label.light {
  color: rgba(0, 0, 0, 0.4);
}
