.groupSettings {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-flow: column;
  flex: 1;
  width: 100%;
  gap: 7rem;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 3rem;
}

.image {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.usersTitle {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.content {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-start;
}

.username {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.addBtn {
  color: var(--green);
  flex-flow: row-reverse;
}

.leaveBtn {
  color: #444;
  align-self: flex-start;
  margin-top: 1.5rem;
  font-size: 1.7rem;
}

.leaveBtnLabel {
  color: var(--danger);
}

.leaveIcon {
  font-size: 2.7rem;
}

.deleteIcon {
  color: var(--danger);
  font-size: 2rem;
}

.user {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.2rem 0;
}
