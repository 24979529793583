@keyframes show-dialog {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes show-background {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  animation: show-background 0.2s linear;
}

.dialog {
  display: flex;
  flex-flow: column;
  row-gap: 4rem;
  padding: 10rem 9.5rem 10rem;
  background-color: var(--white);
  max-height: 65rem;
  max-width: 65rem;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  box-sizing: border-box;
  animation: show-dialog 0.3s var(--ease-out-quad);
  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.children {
  display: flex;
  flex: 1;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 2.4rem;
  justify-content: center;
  width: 100%;
}

.header {
  display: flex;
  flex-flow: column;
  gap: 2.8rem;
}

@keyframes show-loader {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.overlay::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  content: "";
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show-loader 0.2s linear;
}

.formError {
  margin: 1rem 0;
  color: var(--danger);
  font-size: 1rem;
}
