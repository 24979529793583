.form {
  display: flex;
  flex-flow: column;
  row-gap: 4.4rem;
  height: 100%;
}

.header {
  display: flex;
  flex-flow: column;
  row-gap: 0.8rem;
}
