.nav {
  display: flex;
  flex-flow: column;
  background-color: #242424;
  border-radius: 5px;
  min-width: 26rem;
  box-shadow: 0px 4px 17px 0px #00000040;
  color: white;
  overflow: hidden;
  transition: all 0.3s var(--ease-in-out-quad);
}

.button,
.user,
.nav button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: bold;
  text-align: left;
  font-size: 1.6rem;
  line-height: 1.9rem;
  position: relative;
  flex: 1;
  display: flex;
}
.nav button,
.user {
  padding: 1.6rem;
}

.nav button {
  cursor: pointer;
  flex: 1;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.divider {
  background: white;
  opacity: 0.1;
}
.divider:last-child {
  display: none;
}

.user {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
.user .button {
  padding: 0;
  flex-shrink: 0;
}

.userDetails {
  flex: 1;
  display: flex;
  flex-flow: column;
  color: rgba(255, 255, 255, 0.4);
  font-weight: normal;
}
