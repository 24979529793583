@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.payment {
  width: 100%;
  height: 100%;
}

.payment.loading::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: inherit;
  z-index: 1;
  animation: show 0.2s linear;
}

.view {
  border: 1px solid var(--10, rgba(0, 0, 0, 0.1));
  border-radius: 20px;
}

.header {
  padding: 6.4rem 3rem 14rem;
  box-sizing: border-box;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  padding: 3.8rem 0;
}

.optionBtn {
  color: #444444;
  justify-content: flex-start;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.removeBtn {
  flex-flow: row-reverse;
  font-size: 1.2rem;
}

.removeIcon {
  color: var(--danger);
  font-size: 2rem;
  position: relative;
  top: -1px;
}

.name {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardNumber {
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
}

.addBtn {
  color: var(--green);
}

.cardBtn {
  background-color: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  display: grid;
  font-size: inherit;
  grid-template-columns: 44px minmax(auto, 10rem) 1fr;
  align-items: center;
  gap: 2.4rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.logo {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
